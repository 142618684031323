<template>
  <dashboard-page-wrapper title="Analytics">
    <div>
      <analytics-table :seats=seats></analytics-table>
    </div>
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {FETCH_SEATS_REQUEST} from '@/store/storeActions';
import {mapActions, mapGetters} from 'vuex';
import AnalyticsTable from './AnalyticsTable.vue';
import {seatStatus, seatTypes} from '@/store/userConstants';

export default {
  components: {
    DashboardPageWrapper,
    AnalyticsTable,
  },
  data: () => ({
    csvData: {
      name: {title: 'Name'},
      conversion: {title: 'Conversion Rate'},
      send: {title: 'Text Sent'},
      received: {title: 'Text Received'},
      opt: {title: 'OPT'},
    },
  }),
  computed: {
    ...mapGetters([
      'accountSeats',
      'accountSeatsLoaded',
    ]),
    seats() {
      return this.accountSeats.filter(
        (seat) => seat.seatType === seatTypes.TEXTER && seat.status === seatStatus.USED);
    },
  },
  methods: {
    ...mapActions([
      FETCH_SEATS_REQUEST,
    ]),
  },
  created() {
    const payload = {
      fromFlag: 'other',
    };
    !this.accountSeatsLoaded && this.FETCH_SEATS_REQUEST(payload);
  },
};
</script>
