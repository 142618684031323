<template>
  <card bodyClasses="p-0 mt-1" header-classes="border-0">
    <template slot="header">
      <b-row class="align-items-center">
        <b-col sm="8">
          <vue-json-to-csv :json-data="analyticsData"
                           :labels="csvData"
                           class="d-flex justify-content-left align-items-center download-btn" csv-title="AnalyticsData"
          >
            <el-tooltip content="Download" effect="dark" placement="bottom-start">
              <div class="font-weight-bold">Download &nbsp;</div>
            </el-tooltip>
            <i class="ni ni-download"></i>
          </vue-json-to-csv>
        </b-col>
        <b-col sm="4">
          <!-- <analytics-date v-model="date"/> -->
          <div class="d-flex">
            <date-picker v-model="selectedDates"
                         :disabled-date="(date) => date >= new Date()" class="analytic-date-picker" format="DD MMM YYYY"
                         placeholder="Select date" range>
            </date-picker>
            <button :disabled="!isDateSelected" class="btn btn-primary ml-2" @click="getAnalytics()">
              Update
            </button>
          </div>
        </b-col>
      </b-row>
    </template>
    <div>
      <el-table
        ref="analyticTable1"
        :cell-style="{height: '58px'}"
        :data="teamTotal"
        :default-sort="{ prop: 'opt', order: 'ascending' }"
        class="table-responsive table-flush"
        empty-text="Waiting for your team to start texting"
        header-row-class-name="thead-light">
        <el-table-column label="" prop="title">
          <template></template>
        </el-table-column>
        <el-table-column label="Response Rate" prop="conversion">
          <template></template>
        </el-table-column>
        <el-table-column label="Texts Sent" prop="totalSentMessages">
          <template></template>
        </el-table-column>
        <el-table-column label="Texts Received" prop="totalReceivedMessages">
          <template></template>
        </el-table-column>
        <el-table-column label="Opted-Out Candidates" prop="totalOptedOutCandidates">
          <template></template>
        </el-table-column>
      </el-table>
      <el-table
        ref="analyticTable"
        :cell-style="{height: '58px'}"
        :data="analyticsData"
        :default-sort="{ prop: 'opt', order: 'ascending' }"
        class="table-responsive table-flush mb-4"
        empty-text="Waiting for your team to start texting"
        header-row-class-name="thead-light">
        <el-table-column label="name" prop="name">
          <template></template>
        </el-table-column>
        <el-table-column label="Response Rate" prop="conversion" sortable>
          <template v-slot="{ row }">
            {{ row.conversion > 0 ? row.conversion : 0 }} %
          </template>
        </el-table-column>
        <el-table-column label="Texts Sent" prop="totalSentMessages" sortable>
          <template v-slot="{ row }">
            {{ row.totalSentMessages > 0 ? row.totalSentMessages : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="Texts Received" prop="totalReceivedMessages" sortable>
          <template v-slot="{ row }">
            {{ row.totalReceivedMessages > 0 ? row.totalReceivedMessages : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="Opted-Out Candidates" prop="totalOptedOutCandidates" sortable>
          <template v-slot="{ row }">
            {{ row.totalOptedOutCandidates > 0 ? row.totalOptedOutCandidates : 0 }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </card>
</template>
<script>
import {Table, TableColumn} from 'element-ui';
import moment from 'moment';
import {APP_DATE_FORMAT} from '@/util/config.js';
// import AnalyticsDate from '../account/analytics/AnalyticsDate.vue';
import {GET_ACCOUNT_ANALYTICS} from '@/store/storeActions';
import {mapActions, mapGetters} from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    // AnalyticsDate,
    DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueJsonToCsv,
  },
  props: {
    seats: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    date: moment().format(APP_DATE_FORMAT),
    selectedDates: [new Date(), new Date()],
    isFirstTime: true,
    teamTotal: [
      {
        title: 'Team totals',
        conversion: '-',
        totalSentMessages: '-',
        totalReceivedMessages: '-',
        totalOptedOutCandidates: '-',
      },
      {
        title: 'Average',
        conversion: '-',
        totalSentMessages: '-',
        totalReceivedMessages: '-',
        totalOptedOutCandidates: '-',
      }],
    csvData: {
      name: {title: 'Name'},
      conversion: {title: 'Response Rate'},
      totalSentMessages: {title: 'Text Sent'},
      totalReceivedMessages: {title: 'Text Received'},
      totalOptedOutCandidates: {title: 'Opted-Out Candidates'},
    },
    isDateSelected: false,
  }),
  computed: {
    ...mapGetters([
      'accountTextAnalytics',
    ]),
    analyticsData() {
      return this.seats.map((seat) => {
        return this.getMemberTextAnalytics(seat);
      });
    },
  },
  watch: {
    // date: {
    //   handler: function(newVal) {
    //     this.getAccountTextAnalytics(newVal);
    //   },
    //   immediate: true,
    // },
    selectedDates: {
      handler: function(value) {
        this.selectedDates = value;
        this.isDateSelected = value && value[0] !== null ? true : false;
        if (this.isDateSelected && this.isFirstTime) {
          this.getAnalytics();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      GET_ACCOUNT_ANALYTICS,
    ]),
    getAccountTextAnalytics: function(date, endDate) {
      this.GET_ACCOUNT_ANALYTICS({date, endDate});
    },
    getMemberTextAnalytics(seat) {
      if (this.accountTextAnalytics.length === 0) {
        return {};
      }
      const totalSentMessages = this.accountTextAnalytics.totalSentMessages[seat.userId] ?
        this.accountTextAnalytics.totalSentMessages[seat.userId] : 0;
      const totalReceivedMessages = this.accountTextAnalytics.totalReceivedMessages[seat.userId] ?
        this.accountTextAnalytics.totalReceivedMessages[seat.userId] : 0;
      return {
        totalSentMessages,
        totalReceivedMessages,
        name: [seat.user.firstName, seat.user.lastName].filter(Boolean).join(' ').trim(),
        conversion: totalReceivedMessages > 0 && totalSentMessages > 0 ? Math.round(
          (totalReceivedMessages / totalSentMessages) * 100) : 0,
        totalOptedOutCandidates: this.accountTextAnalytics.totalOptedOutCandidates[seat.userId] ?
          this.accountTextAnalytics.totalOptedOutCandidates[seat.userId] : 0,
      };
    },
    getTotalSentMessages() {
      return this.analyticsData.reduce((acc, item) => acc + item.totalSentMessages, 0);
    },
    getTotalReceivedMessages() {
      return this.analyticsData.reduce((acc, item) => acc + item.totalReceivedMessages, 0);
    },
    getTotalOptedOutCandidates() {
      return this.analyticsData.reduce((acc, item) => acc + item.totalOptedOutCandidates, 0);
    },
    getTeamTotal() {
      this.teamTotal[0].totalSentMessages = this.getTotalSentMessages();
      this.teamTotal[0].totalReceivedMessages = this.getTotalReceivedMessages();
      const conversionTotal = Math.round((this.getTotalReceivedMessages() / this.getTotalSentMessages()) * 100);
      this.teamTotal[0].conversion = (!isNaN(conversionTotal) ? conversionTotal : 0) + ' %';
      this.teamTotal[0].totalOptedOutCandidates = this.getTotalOptedOutCandidates();
      this.teamTotal[1].totalSentMessages = this.analyticsData.length > 0 ?
        (this.teamTotal[0].totalSentMessages / this.analyticsData.length).toFixed(2) : 0;
      this.teamTotal[1].totalReceivedMessages = this.analyticsData.length > 0 ?
        (this.teamTotal[0].totalReceivedMessages / this.analyticsData.length).toFixed(2) : 0;
      const conversionAverage = Math.round((this.getTotalReceivedMessages() / this.getTotalSentMessages()) * 100);
      this.teamTotal[1].conversion = this.analyticsData.length > 0 ?
        (!isNaN(conversionAverage) ? conversionAverage / this.analyticsData.length : 0) + ' %' : 0;
      this.teamTotal[1].totalOptedOutCandidates = this.analyticsData.length > 0 ?
        (this.teamTotal[0].totalOptedOutCandidates / this.analyticsData.length).toFixed(2) : 0;
    },
    getAnalytics() {
      this.isFirstTime = false;
      if (this.isDateSelected) {
        const dates = [];
        this.selectedDates.forEach((item) => {
          dates.push(moment(item).format('YYYY-MM-DD'));
        });
        const startDate = dates[0];
        const endDate = dates[1];
        this.getAccountTextAnalytics(startDate, endDate);
      }
    },
  },
  updated() {
    this.getTeamTotal();
  },
};
</script>
<style lang="scss">
.download-btn {
  width: fit-content;
  cursor: pointer;
}

.analytic-date-picker input {
  height: 42px;
}

.mx-calendar-panel-date:last-child {
  display: none;
}
</style>
